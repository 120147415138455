.chatBot{
    position: absolute;
    overflow: hidden;
    .chatBot-Button, .chat-messages{
        position: fixed;
        bottom: 20px;
        right: 15px;
        outline: none;
        z-index: 1000;
    }
    .chat-bgcolor{
        background: #007bb6 !important;
        border-radius: 50%;
    }
    .chat-bgcolor i {
        cursor: pointer;
        color: #fff;
        padding: 18px;
        font-size: 20px;
    }
    .chat-messages{
        border-radius: 4px;
        bottom: 12px;
        z-index: 2000;
        .card{
            width: 350px;
            height: 500px;
            padding: 0;
        }
        .card-header{
            background: #007bb6;
            border-bottom: none;
            .card-title{
                margin-bottom: 0;
                color: #fff;
                font-size: 18px;
            }
            button i{
                color: #fff;
            }
        }
        .card-body{
            background: #f5f5f5;
            .card-title{
                margin-bottom: 0;
                font-size: 10px;
                color: #3c3c3c;
                padding-left: 15px;
            }
            .user .card-text {
                color: #3c3c3c;
                background-color: #fff;
                margin: 10px 0;
                text-align: right;
            }
            .bot .card-text {
                color: #fff;
                background-color: #007bb6;
                margin: 10px 0;
                text-align: left;
            }
            .card-text {
                font-size: 15px;
                padding: 5px 10px;
                border-radius: 10px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                hyphens: auto;
            }
        }
        .card-footer{
            padding: 0;
            input[type="text"], button{
                padding: 12px 20px !important;
                color: #2c2c2c;
                border: none;
                outline: none;
            }
            button{
                background: #007bb6;
                color: #f5f5f5;
                cursor: pointer;
            }
        }
        .overflowAuto{
            overflow-y: auto;
        }
    }
}