/*--- TimeSeries Card ---*/
.timeSeries-card{
    height: 275px;
}

.timeSeries-card.It-chart{
    height: 420px;
}

.timeSeries-card .timeseries-select{
    font-size: 13px;
}

.timeSeries-card .timeseries-select select {
    margin-left: 5px;
}

.timeseries-title{
    font-size: 20px;
}

.timeSeries-Kpi, .timeSerieslatest-Kpi{
    height: 130px;
}
.arc{
    cursor: pointer;
}

.timeSeries-Kpi .card-header, .timeSerieslatest-Kpi .card-header {
    position: absolute;
    padding: 10px;
    padding-left: 5px;
    background: none;
    border-bottom: none;
}

.timeSeries-Kpi .card-header i {
    font-size: 30px;
}

.timeSeries-Kpicard .card-title, .timeSeries-Kpicard .card-title-number, .timeSerieslatest-Kpi .card-title,
.timeSerieslatest-Kpi .card-title-number{
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    padding-right: 22px;
}

.timeSeries-Kpicard .card-title{
    font-size: 14px;
}

.timeSeries-Kpicard .timeSeries-Kpi, .timeSerieslatest-KpiCard .timeSerieslatest-Kpi{
    font-weight: bold;
}

.timeSeries-Kpicard > div:nth-child(1) .timeSeries-Kpi{
    background: $rare-wind-gradient;
}

.timeSeries-Kpicard > div:nth-child(2) .timeSeries-Kpi{
    background: $rare-wind-gradient;
}

.timeSerieslatest-KpiCard > div:nth-child(1) .timeSerieslatest-Kpi{
    background: $rare-wind-gradient;
}

.timeSerieslatest-KpiCard > div:nth-child(2) .timeSerieslatest-Kpi{
    background: $rare-wind-gradient;
}

.timeSerieslatest-KpiCard .uptoDate{
    right: none;
    left: 0;
    font-size: 18px;
    font-weight: bold;
}

.timeSeries-Kpicard .card-title-number{
    top: 25px;
    color: #3C4858;
}

.timeSeries-Kpicard .card-footer{
    background: none;
    margin-top: 15px;
    padding: 5px;
    border-color: #fff
}

.timeSeries-Kpicard .card-footer .text{
    font-size: 18px;
}

.timeSeries-Kpicard .footer-bar{
    border-left: 1px solid #fff;
}

.timeSerieslatest-Kpi .card-header, .timeSeries-Kpi .card-header{
    border-radius: 50px;
    padding: 10px;
}

.timeSerieslatest-Kpi .cvp_percentage{
    position: absolute;
    right: 0;
    padding-top: 20px;
    padding-right: 20px;
}

.timeSerieslatest-Kpi .card-header i, .timeSeries-Kpi .card-header i {
    font-size: 25px;
    position: relative;
    top: 1px;
    left: 0;
    color: #fff;
}

.timeSerieslatest-Kpi .card-title, .timeSerieslatest-Kpi .card-title-number {
    padding: 15px 20px;
}

.timeSerieslatest-Kpi .card-title-number{
    top: 20px;
}

.timeSerieslatest-Kpi .card-footer{
    background: none;
    border-top: none;
    padding: 0;
    font-size: 18px;
}

.timeSerieslatest-Kpi .card-title-arrow i {
    padding: 15px;
    font-size: 20px;
}

.timeSerieslatest-Kpi .card-title-arrow .fa-arrow-circle-down{
    color: #ff4444;
}

.timeSerieslatest-Kpi .card-title-arrow .fa-arrow-circle-up{
    color: #00C851;
}

.timeSerieslatest-KpiCard > div:nth-child(2) .card-header, .timeSeries-Kpicard > div:nth-child(1) .card-header{
    background: $blue-gradient;
}

/*--- Circle Progress Bar ---*/
.progress-Circle{
    width: 40px;
    height: 40px;
}

.progress-SalesAvg-Circle{
    width: 70px;
    height: 70px;
}

#radialchart{
    height:100%;
}

.progress-uploading-Circle{
    width: 150px;
    height: 150px;
}