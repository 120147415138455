%try-for-free{
    .try-free{
      margin-top: 40px;
    }
    .try-for-free{
      input{
        width: 300px;
        display: inline-block;
        margin-right: 20px;
        height: 46px;
        border-radius: 20px;
        padding-left:30px;
      }
      button{
        height: 46px;
      }
      @include breakpoint(xsmall) {
        input{
          width: 200px;
        } 
        button{
          // padding: 
        }
      }
    }
  
  }
  .sd-blue{
    background-color: $aap-sd-blue-bg-color;
    color: #fff;
  }
  .md-blue{
    background-color: $aap-md-blue-bg-color;
    color: #fff;
  }
  .tp-blue{
    background-color: $aap-tr-blue-bg-color;
  }
  .transparent-overlay{
    padding-bottom: 3rem;
    background-color: #D8E8FF;
    border-bottom-left-radius: 100px;
    position: relative;
  }
  
  .solid-overlay {
    background-color: #066CFF;
    border-bottom-left-radius: 200px;
    border-bottom-right-radius: 70px;
    position: relative;
  }
  
  
  .header-title{
    color: #fff;
    font-weight: bold;
  }