.abc_container{
	margin: 40px auto;
	width: 900px;
	height: 800px;
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

.abc-card{
	width: 100%;
	height: 397px;
  	padding: 0px;
}

#pie1{
	grid-area: 1/1/2/2;
	background-color:	#e1e2e3;
	box-shadow: 5px 5px 5px grey;
	display: flex;
  	justify-content: center;
  	align-items: center;
}

#pie2{
	grid-area: 1/2/2/3;
	background-color: #e1e2e3;
	box-shadow: 5px 5px 5px grey;
	display: flex;
  	justify-content: center;
  	align-items: center;
}

#table{
	padding: 100px;
	grid-area: 2/1/3/3;
	background-color:	#e1e2e3;
	box-shadow: 5px 5px 5px grey;
	display: flex;
  	justify-content: center;
}
#myInput{
	position: absolute;
	top: 540px;
	left: 150px;
	height: 20px;
	width: 180px;
}

.pie_title{
	font-size: 30px;
	fill: rgb(78, 77, 76)
}

.caption{
	font-size: 25px;
	stroke: black;
    color: #A0522D;
    caption-side:top;
}

.caption2{
	/* float:right; */
	margin-left: 130px;
	caption-side: top;
	display: table-caption;
	/* right:550px; */
	text-align: top;
	font-size: 30px;
	stroke: black;
	color: #A0522D;
}

.category-table {
	margin-top: 5px;
}

.MuiTableCell-head span div {
	font-weight: bold;
	font-size: 15px;
}