/*--- Item Analysis ---*/
.itemAnalysis-card .treechart{
    padding: 0px;
    min-height: 100%;
    // height: 800px;
}

.itemAnalysis-card-treechart{
    height: 883px;
}

.itemAnalysis-card{
    height: 320px;
}

#treechart{
    width:100%;
    height: 100%;
    min-height: 850px;
}


.itemSalesTimeSeries{
    .axis path,
    .axis line {
        fill: none;
        stroke: #000;
        shape-rendering: crispEdges;
    }

    .x.axis path {
        display: none;
    } 

    label {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    path.arc {
    opacity: 0.9;
    transition: opacity 0.5s;
    }

    path.arc:hover {
    opacity: 0.7;
    }

    .axis line, .axis circle  {
    stroke: #cccccc;
    stroke-width: 1px
    }

    .axis circle {
    fill: none;
    }

    .r.axis text {
    text-anchor: end
    }
}
/*--- KPI CARDS---*/
.itemAnalysis-Kpicard{
    font-size: 20px;
    font-weight: bold;
    padding: 10px 20px;
}

.itemAnalysis-kpicard-name{
    margin-right: 50px;
}

.itemAnalysis-Kpi > div:nth-child(1) .itemAnalysis-Kpicard{
    background: $spring-warmth-gradient;
}

.itemAnalysis-Kpi > div:nth-child(2) .itemAnalysis-Kpicard{
    background: $winter-neva-gradient;
}

.itemAnalysis-Kpi > div:nth-child(3) .itemAnalysis-Kpicard{
    background: $tempting-azure-gradient;
}