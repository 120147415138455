.customerAnalysis {
    width: 100%;
    height: 87vh;
    padding: 0px;
}

.customer-sidebar {
    height: 87vh;
    overflow: hidden;
}

.customer-sidebar-scroll {
    overflow-y: scroll;
}

.customer-sidebar-Toggle {
    background: #fff;
    padding: 0px;
}

.customer-List {
    text-align: center;
    padding: 8px 0px;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
}

.customer-sidebar-Toggle {
    border-right: 4px solid #221529;
    .menu-unfold{
        padding: 10px 30px;
    }
    .menu-fold{
        padding: 10px 30px;
    }
}