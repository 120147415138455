// .Domain_module_size{
//     display: flex;
//     height: 100vh;
//     align-items: center;
// }

.domain-bg{
    background: #007bff;
    height: 100%; 
    width: 100%;
    min-height: 100vh;
    color: #fff;
}

.selectDomain .card{
    padding: 0;
}

.domain_selection{
    font-size: 40px;
    padding: 50px;
}

.selectDomain {
    .card-img-top{
        height: 180px;
        width: 100%;
    }
    .card-title, .card-text{
        margin-bottom: 5px;
    }
    .card-text{
        height: 60px;
        overflow: hidden;
        text-overflow: ellipsis; 
    }
    .btn-sm{
        margin: 5px 0px;
    }
    span{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgb(105,105,105);
        opacity: 0.4;
    }
    span h5{
        position: relative;
        top: 50%;
        left: 50%;
        background: #000;
        padding: 10px;
        color: #fff;
        width: 120px;
        font-size: 15px;
        font-weight: 600;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        transform: translate(-50%,-50%);
    }
    .comming-soon{
        margin: 45px;
    }
}