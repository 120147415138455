.csvFileDownloader{
    background: none;
    border: none;
    color: #1D58B1;
    text-decoration: underline;
    letter-spacing: 1px;
}

.fileProcessing {
    text-align: center;
    font-size: 20px;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
}

.csvFileDownloader:hover{
    color: #1890ff;
}

.csvFileDownloader:active{
    outline: none;
    border: none;
}

.csvFileDownloader:focus{
    outline: none;
}

.uploadingFile-Heading, .uploaded-file{
    font-weight: bold;
    padding-left: 0px !important;
}