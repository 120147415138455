.cashflowForecast {
    width: 100%;
    height: 400px;
    padding: 0;
}

.monthlyCashBalanceForecast {
    div.tooltip {	
        position: absolute;			
        text-align: center;			
        width: 80px;					
        height: 28px;					
        padding: 2px;				
        font: 12px sans-serif;		
        background: rgb(33, 72, 122);	
        border: 0px;		
        border-radius: 8px;			
        pointer-events: none;			
    }
    // svg {
    //     max-width: 100%;
    //     min-height: 400px;
    // }
}

.weeklyHistPredData {
    // #weeklyHistPredData{
    //     width:100%;
    //     height:100%;
    // }
    
    // svg{
    //     width:100%;
    //     height: 100%;
    // }
    .line {
        fill:none;
        stroke: steelblue;
        stroke-width: 1.5px;
    }
    path.domain {
        fill: white; 
    }
    .tick text {
        fill:black;
    }
    .area{
        /* fill: #00e600; */
        fill: #FFA07A;
        opacity: 0.3;
    }
    .caption{
        font-size: 18px;
        font-family: cursive;
        font-weight: bold;
        color: steelblue;
    }
}