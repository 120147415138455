.dataRepository{
    background: #F6F7EB;
    height: 89.8vh;

    .noData {
        font-size: 20px;
        text-align: center;
    }

    .accordion{
        border-top: 3px solid rgba(0, 123, 182, 1);
    }

    .card{
        margin: 1px;
        padding: 0;
    }
    .card .card-header{
        position: relative;
        background-color: transparent !important;
        border: none;
        padding-right: 80px;

        transition: all 0.4s ease;
    }
    .btn-link {
        padding: 0;
        color: rgba(0, 123, 182, 1) !important;
        font-weight: bold !important;
        text-align: left !important;
        text-decoration: none !important;
        font-size: 20px;
        text-transform: none;
        text-decoration: none;
    }
    .btn-link i {
        position: absolute;
        color: rgba(0, 123, 182, 1);
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        transition: all 0.4s ease-out;
    }
    .btn-link.collapsed, .btn-link.collapsed i {
        color: #3c3c3c !important;
    }
    .btn-link.collapsed i{
        transform: translateY(-50%) rotate(180deg);
    }
    .active {
        border-top: 1px solid rgba(0, 123, 182, 1) !important;
        border-bottom: 1px solid rgba(0, 123, 182, 1) !important;
        .btn-link::before {
            content: '';
            height: 25px;
            width: 25px;
            position: absolute;
            background: rgba(0, 123, 182, 1) !important;
            z-index: 1;
            transform: rotate(45deg);
            left: -12px;
            top: 33%;
        }
    }
    .btn-link:hover {
        color: rgba(0, 123, 182, 1) !important;
    }
    .card-body{
        margin: auto 10px;
        background: #fff;
        font-size: 18px;
    }

    .file-Details {
        margin: 0;
        padding: 0;
        .file_heading{
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            color: #555;
            margin-bottom: 2px;
        }
        .file_Id, .fileId{
            text-align: center;
            font-size: 16px;
        }
        .fileId {
            text-align: left;
        }
        .file_details {
            margin-top: 15px;
            padding: 15px;
            height: auto;
            border-radius: 10px;
            box-sizing: border-box;
            box-shadow: 0 5px 13px 0 rgba(0, 123, 182, 1);
        }
        .file_details > div {
            color: #3c3c3c;
            font-size: 14px;
        }
        .fa-check-circle {
            color: #4BB543;
        }
        .fa-times-circle {
            color: #dc3545;
        }
    }
}