.timeSeries.Prediction-card{
    height: 550px;
    padding:40px;    
}

.timeSeries.pred-chart{
    padding: 20px 0 0 0;
    height: 400px;
    background: #F5F5DC;
}

/*--- Predicton Chart ---*/
#pchart{
    width: 100%;
    min-height: 100%;
    min-height: 400px;
}

.timepredictionarea{
    fill: #FFA07A;
    opacity: 0.3;
}

.chart_title{
    text-anchor: middle;
    font-size: 30px;
    stroke: black;
    fill: #FFA07A;
    font-family: 'Courgette', cursive;
  }
  