/*--- KPI card ---*/
.card{
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 20px;
}

.kpi-360 .card-header{
  position: absolute;
  padding: 26px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  border-bottom: none;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4)
}

.kpi-360 .card-header i{
  color: #FFF;
  font-size: 25px;
}

.kpi-360 .card-title, .card-title-number{
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

.kpi-360 .card-title{
  color: #999;
  font-size: 14px;
}

.card-title-number{
  top: 25px;
  color: #3C4858;
}

.kpi-360 > div:nth-child(1) .card-header{
  background: $purple-gradient;
}

.kpi-360 > div:nth-child(2) .card-header{
  background: $young-passion-gradient;
}

.kpi-360 > div:nth-child(3) .card-header{
  background: linear-gradient(to top,#30cfd0 0,#330867 100%);
}

.kpi-360 > div:nth-child(4) .card-header{
  background: linear-gradient(120deg,#f093fb 0,#f5576c 100%);
}

.kpi-360 > div:nth-child(5) .card-header{
  background: $blue-gradient;
}

/*--- Insights Graph ---*/
.insight-card{
  width: 100%;
  height: 400px;
  padding: 0px;
}

.card-graph{
  position: absolute;
  top: 35px;
  left: 20px;
  font-weight: bold;
}

.card-graph-Content{
  font-size: 25px;
  padding: 10px;
  font-weight: 500;
}

/*--- LINE CHART ---*/
.title {
  font-size: 2.5em;
  text-anchor: middle;
  fill: #7cb5ec;
  stroke: #696969
}

.line {
  fill: none;
  stroke: #7cb5ec;
  stroke-width: 4px;
  opacity: 0.5;
}
.xaxisB {
  font-size: 1.0em;
  fill: #696969;
  text-anchor: end;
}  
.text{
  text-anchor: middle;
  font-size: 30px;
}


div.tooltip {   
  position: absolute;         
  text-align: center;  
  z-index: 100;       
  width: 80px;                 
  padding: 2px;               
  font: 12px sans-serif;      
  background: white; 
  border: 0px;        
  border-radius: 8px;         
  pointer-events: none;   
  border-color: black;        
}

.tick text {
  font-size: 1.0em;
  fill: #696969;
  
}

.tick line {
  stroke: black;
  stroke-dasharray: 1,1;
  stroke-width: 2px;
  opacity: 0.4;
  
}

.Yaxis-label {
  font-size: 1.8em;
  fill: #696969;
  text-anchor: middle;
}

/*--- Donut Chart ---*/
polyline{
  opacity: .3;
  stroke: black;
  stroke-width: 2px;
  fill: none;
  shape-rendering: geometricPrecision;
}  
.ctitle{
  font-size: 20px;
  font: 40px "Alfa Slab One", cursive;
  stroke:rgb(154, 168, 177);
  stroke-width: 1px;
}
.donutCard{
  padding: 20px;
  background:cornsilk;
}

/*--- GroupBar Chart ---*/
.axis .domain {
  display: none;
}

.ctitleGroupBar{  
  font: 38px "Alfa Slab One", cursive;
  stroke:rgb(154, 168, 177);
  stroke-width: 1px;
}
.groupedBarCard{
  padding: 10px;
  background:cornsilk;
}

/*--- Scatter Chart ---*/
.bubble_title{
  fill: #7cb5ec;
  stroke: #696969;
  font-size: 30px;

}
div.tooltip {   
  position: absolute;         
  text-align: center;  
  z-index: 100;       
  width: 100px;                    
  padding: 2px;               
  font: 12px sans-serif;      
  background: white; 
  border: 0px;        
  border-radius: 8px;         
  pointer-events: none;   
  border-color: black;        
}