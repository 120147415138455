.mbk-analysis{
    .card-graph{
        height:100%;
        width:100%;
        top:1px;
        left:0px;
        display: flex;
        justify-content: flex-start;
    }

    #bubble{
        height:100%;
        min-width: 800px;
    }

    .insight-card{
        min-height:550px;
        padding:10px !important;
    }

    svg{
        // width:100%;
        height: 100%;
    }
}

.mba-card{
    height: 90vh;
    padding: 0px;
}

.mdb-sidebar{
    height: 90vh;
    overflow: hidden;
}

.mdb-sidebar-scroll{
    overflow-y: scroll;
}

.mdb-sidebar-Toggle{
    background: #fff;
    padding: 0px;
}

#bubble .title {
    font-size: 20px;
}

.mdb-sidebar-Toggle{
    border-right: 4px solid #221529;
    .menu-unfold{
        padding: 10px 30px;
    }
    .menu-fold{
        padding: 10px 30px;
    }
}