/*--- SPIN LOADING ---*/
.page-loading{
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center
}

/*--- LOGIN && REGISTER ---*/
.login-container{
    height: 100vh;
}

.cb-slideshow li {
    list-style-type: none
}

.cb-slideshow:after { 
    content: '';
    background: transparent url(../src/Resources/images/Login_Page/pattern.png) repeat top left; 
}
.cb-slideshow li span { 
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: none;
    opacity: 0;
    z-index: 0;
	/* -webkit-backface-visibility: hidden; */
    -webkit-animation: imageAnimation 36s linear infinite 0s;
    -moz-animation: imageAnimation 36s linear infinite 0s;
    -o-animation: imageAnimation 36s linear infinite 0s;
    -ms-animation: imageAnimation 36s linear infinite 0s;
    animation: imageAnimation 36s linear infinite 0s; 
}
.cb-slideshow li div { 
    z-index: 1000;
    position: absolute;
    bottom: 30px;
    top: 0px;
    width: 100%;
    text-align: center;
    opacity: 0;
    -webkit-animation: titleAnimation 36s linear infinite 0s;
    -moz-animation: titleAnimation 36s linear infinite 0s;
    -o-animation: titleAnimation 36s linear infinite 0s;
    -ms-animation: titleAnimation 36s linear infinite 0s;
    animation: titleAnimation 36s linear infinite 0s; 
}
.cb-slideshow li div h3 { 
    font-size: 60px;
    padding: 0;
    line-height: 200px; 
    color: rgba(169,3,41, 0.8);
    text-shadow:     0 1px 0 hsl(174,5%,80%),
	                 0 2px 0 hsl(174,5%,75%),
	                 0 3px 0 hsl(174,5%,70%),
	                 0 4px 0 hsl(174,5%,66%),
	                 0 5px 0 hsl(174,5%,64%),
	                 0 6px 0 hsl(174,5%,62%),
	                 0 7px 0 hsl(174,5%,61%),
	                 0 8px 0 hsl(174,5%,60%),
	
	                 0 0 5px rgba(0,0,0,.05),
	                0 1px 3px rgba(0,0,0,.2),
	                0 3px 5px rgba(0,0,0,.2),
	               0 5px 10px rgba(0,0,0,.2),
	              0 10px 10px rgba(0,0,0,.2),
	              0 20px 20px rgba(0,0,0,.3);
}
.cb-slideshow li:nth-child(1) span { 
    background-image: url(../src/Resources/images/Login_Page/1.jpg) 
}
.cb-slideshow li:nth-child(2) span { 
    background-image: url(../src/Resources/images/Login_Page/2.jpg);
    -webkit-animation-delay: 6s;
    -moz-animation-delay: 6s;
    -o-animation-delay: 6s;
    -ms-animation-delay: 6s;
    animation-delay: 6s; 
}
.cb-slideshow li:nth-child(3) span { 
    background-image: url(../src/Resources/images/Login_Page/3.jpg);
    -webkit-animation-delay: 12s;
    -moz-animation-delay: 12s;
    -o-animation-delay: 12s;
    -ms-animation-delay: 12s;
    animation-delay: 12s; 
}
.cb-slideshow li:nth-child(4) span { 
    background-image: url(../src/Resources/images/Login_Page/4.jpg);
    -webkit-animation-delay: 18s;
    -moz-animation-delay: 18s;
    -o-animation-delay: 18s;
    -ms-animation-delay: 18s;
    animation-delay: 18s; 
}
.cb-slideshow li:nth-child(5) span { 
    background-image: url(../src/Resources/images/Login_Page/5.jpg);
    -webkit-animation-delay: 24s;
    -moz-animation-delay: 24s;
    -o-animation-delay: 24s;
    -ms-animation-delay: 24s;
    animation-delay: 24s; 
}
.cb-slideshow li:nth-child(6) span { 
    background-image: url(../src/Resources/images/Login_Page/6.jpg);
    -webkit-animation-delay: 30s;
    -moz-animation-delay: 30s;
    -o-animation-delay: 30s;
    -ms-animation-delay: 30s;
    animation-delay: 30s; 
}
.cb-slideshow li:nth-child(2) div { 
    -webkit-animation-delay: 6s;
    -moz-animation-delay: 6s;
    -o-animation-delay: 6s;
    -ms-animation-delay: 6s;
    animation-delay: 6s; 
}
.cb-slideshow li:nth-child(3) div { 
    -webkit-animation-delay: 12s;
    -moz-animation-delay: 12s;
    -o-animation-delay: 12s;
    -ms-animation-delay: 12s;
    animation-delay: 12s; 
}
.cb-slideshow li:nth-child(4) div { 
    -webkit-animation-delay: 18s;
    -moz-animation-delay: 18s;
    -o-animation-delay: 18s;
    -ms-animation-delay: 18s;
    animation-delay: 18s; 
}
.cb-slideshow li:nth-child(5) div { 
    -webkit-animation-delay: 24s;
    -moz-animation-delay: 24s;
    -o-animation-delay: 24s;
    -ms-animation-delay: 24s;
    animation-delay: 24s; 
}
.cb-slideshow li:nth-child(6) div { 
    -webkit-animation-delay: 30s;
    -moz-animation-delay: 30s;
    -o-animation-delay: 30s;
    -ms-animation-delay: 30s;
    animation-delay: 30s; 
}
/* Animation for the slideshow images */
@-webkit-keyframes imageAnimation { 
    0% { opacity: 0;
    -webkit-animation-timing-function: ease-in; }
    8% { opacity: 1;
         -webkit-animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}
@-moz-keyframes imageAnimation { 
    0% { opacity: 0;
    -moz-animation-timing-function: ease-in; }
    8% { opacity: 1;
         -moz-animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}
@-o-keyframes imageAnimation { 
    0% { opacity: 0;
    -o-animation-timing-function: ease-in; }
    8% { opacity: 1;
         -o-animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}
@-ms-keyframes imageAnimation { 
    0% { opacity: 0;
    -ms-animation-timing-function: ease-in; }
    8% { opacity: 1;
         -ms-animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}
@keyframes imageAnimation { 
    0% { opacity: 0;
    animation-timing-function: ease-in; }
    8% { opacity: 1;
         animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}
/* Animation for the title */
@-webkit-keyframes titleAnimation { 
	0% {
	    opacity: 0;
	    -webkit-transform: translateX(200px);
	}
	8% {
	    opacity: 1;
	    -webkit-transform: translateX(0px);
	}
	17% {
	    opacity: 1;
	    -webkit-transform: translateX(0px);
	}
	19% {
	    opacity: 0;
	    -webkit-transform: translateX(-400px);
	}
	25% { opacity: 0 }
	100% { opacity: 0 }
}
@-moz-keyframes titleAnimation { 
	0% {
	    opacity: 0;
	    -moz-transform: translateX(200px);
	}
	8% {
	    opacity: 1;
	    -moz-transform: translateX(0px);
	}
	17% {
	    opacity: 1;
	    -moz-transform: translateX(0px);
	}
	19% {
	    opacity: 0;
	    -moz-transform: translateX(-400px);
	}
	25% { opacity: 0 }
	100% { opacity: 0 }
}
@-o-keyframes titleAnimation { 
	0% {
	    opacity: 0;
	    -o-transform: translateX(200px);
	}
	8% {
	    opacity: 1;
	    -o-transform: translateX(0px);
	}
	17% {
	    opacity: 1;
	    -o-transform: translateX(0px);
	}
	19% {
	    opacity: 0;
	    -o-transform: translateX(-400px);
	}
	25% { opacity: 0 }
	100% { opacity: 0 }
}
@-ms-keyframes titleAnimation { 
	0% {
	    opacity: 0;
	    -ms-transform: translateX(200px);
	}
	8% {
	    opacity: 1;
	    -ms-transform: translateX(0px);
	}
	17% {
	    opacity: 1;
	    -ms-transform: translateX(0px);
	}
	19% {
	    opacity: 0;
	    -ms-transform: translateX(-400px);
	}
	25% { opacity: 0 }
	100% { opacity: 0 }
}
@keyframes titleAnimation { 
	0% {
	    opacity: 0;
	    transform: translateX(200px);
	}
	8% {
	    opacity: 1;
	    transform: translateX(0px);
	}
	17% {
	    opacity: 1;
	    transform: translateX(0px);
	}
	19% {
	    opacity: 0;
	    transform: translateX(-400px);
	}
	25% { opacity: 0 }
	100% { opacity: 0 }
}
/* Show at least something when animations not supported */
.no-cssanimations .cb-slideshow li span{
	opacity: 1;
}

@media screen and (max-width: 1140px) { 
    .cb-slideshow li div h3 { font-size: 70px }
}
@media screen and (max-width: 600px) { 
    .cb-slideshow li div h3 { font-size: 60px }
}

.login-form{
    padding: 10px;
}

.logo img{
    height: 50px;
    width: 100px;
}

.valid-feedback, .invalid-feedback {
    margin-left: 40px;
}

.login_heading, .register_heading {
    position: relative;
    margin-bottom: 50px;
    color: #282726;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 1px;
}

.register_heading {
    margin-bottom: 10px;
}

.user_card, .register_user_card {
    height: auto;
    width: 500px;
    margin-top: 20px;
    margin-bottom: auto;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 40px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}

.register_user_card {
    margin: 0;
    padding: 0;
    height: auto;
    padding-bottom: 40px;
    justify-content: flex-start;
}

.brand_logo_container {
    position: absolute;
    height: 120px;
    width: 120px;
    top: -55px;
    border-radius: 50%;
    background: #fff;
    padding: 10px;
    text-align: center;
}
.brand_logo {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 2px solid white;
}

.brand_signup, .brand_signin, .brand_forgotPassword, .brand_ResetPassword {
    margin-top: 20px;
    padding: 10px;
    font-size: 25px;
    font-weight: 700;
    color: #262626;
}

.brand_forgotPassword, .brand_ResetPassword{
    margin-top: 0px;
    padding: 0;
    padding-top: 10px;
}

.brand_signin {
    position: relative;
    margin: 0;
    top:40px;
    padding: 0;
}

.checkbox-label {
    font-weight: bold;
    font-size: .9em;
}

.checkbox label:after{
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr{
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.2em;
    height: 1.2em;
    margin-right: .5em;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 12%;
}

.checkbox label input[type="checkbox"]{
    display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon{
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all .3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon{
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr{
    opacity: .5;
}

.up_brand, .in_brand {
    background-image: linear-gradient(40deg,#ff6ec4,#7873f5)!important;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.in_brand {
    background-image: linear-gradient(40deg,#45cafc,#303f9f)!important;
}

.form_container {
    margin-top: 20px;
}

.social_icon {
    padding-bottom: 10px;
}

.social_icon ul li{
    list-style: none;
    margin: 0 40px;
}
.social_icon ul li .fab{
    font-size: 40px;
    color: #262626;
    line-height: 80px;
    transition: .5s;
}
.social_icon ul li a{
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    background-color: #fff;
    text-align: center;
    transform: perspective(100px) rotate(-30deg) skew(25deg) translate(0,0);
    transition: .5s;
    box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);
}
.social_icon ul li a::before{
    content: "";
    position: absolute;
    top: 10px;
    left: -20px;
    height: 100%;
    width: 20px;
    background: #b1b1b1;
    transition: .5s;
    transform: rotate(0deg) skewY(-45deg);
}
.social_icon ul li a::after{
    content: "";
    position: absolute;
    top: 80px;
    left: -11px;
    height: 20px;
    width: 100%;
    background: #b1b1b1;
    transition: .5s;
    transform: rotate(0deg) skewX(-45deg);
}
.social_icon ul li a:hover{
    transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(20px, -20px);
    box-shadow: -50px 50px 50px rgba(0, 0, 0, 0.5);
}
.social_icon ul li:hover .fab{
    color: #fff;
}
.social_icon ul li a:hover{
    transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(20px, -20px);
    box-shadow: -50px 50px 50px rgba(0, 0, 0, 0.5);
}

.social_icon ul li:hover:nth-child(1) a{
    background: #0077B5;
}

.social_icon ul li:hover:nth-child(1) a:before{
    background: #036aa0;
}
.social_icon ul li:hover:nth-child(1) a:after{
    background: #0d82bf;
}

.social_icon ul li:hover:nth-child(2) a{
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}

.social_icon ul li:hover:nth-child(2) a:before{
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}
.social_icon ul li:hover:nth-child(2) a:after{
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}

/*--- LOGIN RESPONSIVE ---*/
@media (max-width: 767px) {
    .login_form_image{
        display: none;
    }
}