.expense{
    width: 100%;
    height: 450px;
    padding: 0px;
}

.headwiseOutflow{
    .label{
        font-size:8px;
    }
    .bar {
        fill: #6F257F;
    }
}