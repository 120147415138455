.navigation-menu {
    .navbar-brand strong{
        font-size: 20px;
        font-weight: bold;
        color: #2196f3 !important;
    }
    
    .nav-link{
        font-weight: bold;
        font-size: 16px;
        color: #2196f3 !important;
    }

    .dropdown-menu {
        margin: 0;
        border: none;
    }

    .dropdown > .dropdown-toggle:active {
        pointer-events: none;
    }

    .dropdown-default {
        padding: 0;
    }

    .dropdown-default {
        margin-top: 8px;
    }

    .mega-menu {
        background-color: transparent;
    }

    .dropdown-item {
        padding: 12px 40px 12px 20px;
        font-size: 15px;
        color: #3c3c3c;
        font-weight: lighter;
        background-color: #fff;
        transform-origin: center;
        animation: rotate 1s ease 1;
    }

    @keyframes rotate {
        0%{
            transform: perspective(600px) rotatey(90deg);
            transform-origin: center;
        }
        100%{
            transform: perspective(600px) rotatey(0deg);
            transform-origin: center;
        }
    }

    .dropdown-item:nth-of-type(1) {
        animation-delay: 0s;
    }
    .dropdown-item:nth-of-type(2) {
        animation-delay: 0.1s;
    }
    .dropdown-item:nth-of-type(3) {
        animation-delay: 0.2s;
    }
    .dropdown-item:nth-of-type(4) {
        animation-delay: 0.3s;
    }
    .dropdown-item:nth-of-type(5) {
        animation-delay: 0.4s;
    }
    .dropdown-item:nth-of-type(6) {
        animation-delay: 0.5s;
    }

    .dropdown-item:hover {
        color: #fff;
        background-color: #2196f3;
    }
    
    // .navbar .dropdown-menu a{
    //     color: #444 !important;
    // }
    
    .custom-toggler.navbar-toggler {
        border-color: rgba(33, 150, 243, 1);
    }
    
    .custom-toggler .navbar-toggler-icon{
        width: 18px!important;
        color: #fff;
    }
    
    .custom-toggler{
        border-color: rgba(255,255,255, 1); 
        background-color: rgba(33, 150, 243, 1);
    }
}

#logo_custom{
    margin-top: -6px;
}

.cRD{
    border-radius: 50px;
    padding: 2px 20px;
    position: relative;
    top: -4px;
    background: $aqua-gradient;
    color: #fff;
    z-index: 1;
}

.cRD-submenu{
    font-size: 12px;
}

.cRD-link{
    margin-left: 20px;
    font-weight: bold;
}

.error-msgs{
    color: #dc3545;
    font-size: 11.2px;
    margin-top: 0px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 760px) { 
    #logo_custom{
        width: 40%;
    }
}