$btn-bg-color: transparent;
$btn-color: #fff;
$aap-primary-btn-bg-color:#3B98F5;
$aap-primary-btn-color:#fff; 
$aap-warning-btn-bg-color: #FADC0E;
$aap-warning-btn-color:#000;
$aap-sd-blue-bg-color: #066CFF;
$aap-md-blue-bg-color: #71A7FF;
$aap-tr-blue-bg-color: #D8E8FF;


/*--- Gradient Colors ---*/
$purple-gradient: linear-gradient(40deg,#ff6ec4,#7873f5);
$blue-gradient: linear-gradient(40deg,#45cafc,#303f9f);
$aqua-gradient: linear-gradient(40deg,#2096ff,#05ffa3);
$peach-gradient: linear-gradient(40deg,#ffd86f,#fc6262);
$warm-flame-gradient: linear-gradient(45deg,#ff9a9e 0,#fad0c4 99%,#fad0c4 100%);
$young-passion-gradient: linear-gradient(to right,#ff8177 0,#ff867a 0,#ff8c7f 21%,#f99185 52%,#cf556c 78%,#b12a5b 100%);
$morpheus-den-gradient: linear-gradient(to top,#30cfd0 0,#330867 100%);
$tempting-azure-gradient : linear-gradient(120deg,#84fab0 0,#8fd3f4 100%);
$near-moon-gradient : linear-gradient(to top,#5ee7df 0,#b490ca 100%);
$spring-warmth-gradient : linear-gradient(to top,#fad0c4 0,#ffd1ff 100%);
$rainy-ashville-gradient : linear-gradient(to top,#fbc2eb 0,#a6c1ee 100%);
$winter-neva-gradient : linear-gradient(120deg,#a1c4fd 0,#c2e9fb 100%);
$deep-blue-gradient : linear-gradient(120deg,#e0c3fc 0,#8ec5fc 100%);
$lady-lips-gradient : linear-gradient(to top,#ff9a9e 0,#fecfef 99%,#fecfef 100%);
$juicy-peach-gradient : linear-gradient(to right,#ffecd2 0,#fcb69f 100%);
$dusty-grass-gradient : linear-gradient(120deg,#d4fc79 0,#96e6a1 100%);
$rare-wind-gradient : linear-gradient(to top,#a8edea 0,#fed6e3 100%);