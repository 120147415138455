.home-logo img{
  width: 120px;
  height: 50px;
}

.slick-prev:before, .slick-next:before{
  color: #262626;
}

section {
  overflow: hidden;
}

.top-heading{
  animation-duration: 1s;
  animation-delay: 2s;
}

.top-list{
  animation-duration: 1s;
  animation-delay: 3s;
}

.section-heading{
  text-align: center;
  h1{
    font-size: 36px;
    color: #555;
    font-weight: bold;
    @include breakpoint(small){
      font-size: 26px;
    }
  }
}

.topShowCase, .topShowCase1, .topShowCase2, .topShowCase3, .topShowCase4, .topShowCase5,
.topShowCase6, .topShowCase7, .topShowCase8, .topShowCase9, .topShowCase10, .topShowCase11 {
  position: absolute;
  width: 250px;
  height: 200px;
  background: #fff;
  -ms-transform: rotate(-15deg); /* IE 9 */
  -webkit-transform: rotate(-15deg); /* Safari 3-8 */
  transform: rotate(-15deg);
  box-shadow: 0 20px 16px 5px rgba(0,0,0,0.2);
}

.topShowCase{
  top: -280px;
  left: -750px;
  animation: roll 10s infinite;
  img{
    width: 250px;
    height: 200px;
  }
}

.topShowCase1{
  top: -65px;
  left: -692px;
  animation: roll 10s infinite;
  img{
    width: 250px;
    height: 200px;
  }
}

.topShowCase2{
  top: 130px;
  left: -570px;
  width: 250px;
  height: 220px;
  img{
    width: 250px;
    height: 220px;
  }
}

.topShowCase3, .topShowCase7{
  top: 64px;
  left: -310px;
  width: 220px;
  height: 180px;
  img{
    width: 220px;
    height: 180px;
  }
}

.topShowCase4{
  top: 1px;
  left: -80px;
  width: 220px;
  height: 160px;
  img{
    width: 220px;
    height: 160px;
  }
}

.topShowCase5{
  top: -59px;
  left: 150px;
  width: 200px;
  height: 160px;
  img {
    width: 200px;
    height: 160px;
  }
}

.topShowCase6{
  top: -117px;
  left: 365px;
  width: 200px;
  height: 180px;
  img{
    width: 200px;
    height: 180px;
  }
}

.topShowCase7{
  top: 260px;
  left: -259px;
}

.topShowCase8{
  top: 175px;
  left: -35px;
  width: 220px;
  height: 160px;
  img{
    width: 220px;
    height: 160px;
  }
}

.topShowCase9{
  top: 114px;
  left: 196px;
  width: 200px;
  height: 160px;
  img{
    width: 200px;
    height: 160px;
  }
}

.topShowCase10{
  top: 75px;
  left: 417px;
  width: 200px;
  height: 180px;
  img{
    width: 200px;
    height: 180px;
  }
}

.topShowCase11{
  top: 318px;
  left: 22px;
  width: 438px;
  height: 270px;
  img{
    width: 438px;
    height: 270px;
  }
}

.hovereffect {
  float: left;
  overflow: hidden;
  text-align: center;
  cursor: default;
  background: -webkit-$deep-blue-gradient;
  background: $deep-blue-gradient;
  // background: transparent;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 50px 20px;
}

.hovereffect img {
  display: block;
  position: relative;
  max-width: none;
  // width: calc(100% + 20px);
  // -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  // transition: opacity 0.35s, transform 0.35s;
  // -webkit-transform: translate3d(-10px,0,0);
  // transform: translate3d(-10px,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hovereffect:hover img {
  opacity: 0.4;
  filter: alpha(opacity=40);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.hovereffect h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 17px;
  overflow: hidden;
  padding: 1px 0;
  background-color: transparent;
}

.hovereffect h2:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #777;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0);
}

.hovereffect:hover h2:after {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.hovereffect a, .hovereffect p {
  color: #444;
  font-weight: bold;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}

.hovereffect:hover a, .hovereffect:hover p {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.content-type{
  margin-left: 65px;
}

.register_inputTag{
  background-color: #fff !important;
}

@keyframes roll {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container-fluid{
    &.homepage{
      .container{
        padding: 2rem 0rem;
      }
    }
    &.intro{
      @include breakpoint(medium){
        padding-left: 50px;
        padding-right: 0;
        padding-bottom: 600px;
        .curve{
          padding-bottom: 5rem;
          border-bottom-left-radius: 300px;
          border-bottom-right-radius: 60%;
        }
      }
      @media only screen and (max-width: $lg-device){
        .showCase{
          display: none !important;
        }
        padding-bottom: 40px;
      }
      .nav-info{
        a:first-child{
          margin-right: 20px;
        }
        &.curve{
          border-bottom-left-radius: 50%;
          border-bottom-right-radius: 50%;
        }
      }
      .description{
        max-width: 400px;
        margin: 0 auto;
        color: #fff;
      }
    }
    &.about-aap{
      background: #D8E8FF;
      @include breakpoint(medium){
        .sub-section{
          max-width: 700px;
        }
        h2{
          width: calc( 100% - 50px )
        }
      }
      h2{
        margin-left: 1rem;
      }
      .react-tabs{
        .nav{
          border-bottom: 1px solid #ccc;
        }
        .react-tabs__tab-panel{
          margin-top: 50px;
        }
        .react-tabs__tab--selected{
          border-bottom: 1px solid #777;
        }
      }
    }
    &.need-of-app{
      
      @include breakpoint(medium){
        &.curve{
          border-top-right-radius: 70%;
        }
        &.two{
          border-top-right-radius: 0;
          border-bottom-right-radius: 300px;
          border-bottom-left-radius: 60%;
        }
        .container{
          padding-top: 100px !important;
          padding-bottom: 2rem !important;
        }
        .col-sm-6{
          height: 450px;
          .left-top-section{
            width: calc(100% - 110px);
            height: 250px;
            background-color: #fff;
            img{
              width: 100%;
              height: 250px;
            }
          }
          .left-bottom-section{
            width: calc(100% - 110px);
            height: 250px;
            position: absolute;
            bottom: 50px;
            right: 10px;
            background-color: #fff;
            img{
              width: 100%;
              height: 250px;
            }
          }
        }
      }
    }
    &.footer-section{
      .try-for-free{
        input{
          background-color: transparent;
        }
      }
    }
    @include breakpoint(medium){
      &.footer-section{
        border-top-left-radius: 20%;
        border-bottom-left-radius: 20%;
        border-top-right-radius: 40%;
        border-bottom-right-radius: 30%;
        .container{
          padding-top: 100px !important;
          padding-bottom: 50px !important;
        }
      }
    }
    @include breakpoint(smaller_and_medium) {
      &.homepage{
        .container{
          padding: 2rem 1rem;
          max-width: 100%;
        }
      }
    }
    @include breakpoint(xsmall){
      &.homepage{
        .container{
          padding: 2rem 1rem;
        }
      }
      .home-logo img{
        width: 90px;
        height: 40px;
      }
    }
    @include breakpoint(wide) {
      &.homepage{
        .container{
          padding: 2rem 5rem 5rem;
        }
      }
    }
  
  }
  
  .key-queries{
    @include breakpoint(medium_and_smaller){
      .container{
        max-width: 100%;
        padding: 2rem 0 !important;
      }
    }
    h2{
      color: #000;
      max-width: 500px;
      margin: 0 auto;
    }
    .quest-ctnr{
      margin-top: 2rem;
        .quotation{
          color: #066CFF;
          font-weight: bold;
          font-size: 1.5rem;
        }
        .quote-type{
          font-weight: bold;
        }
    }
    .content{
      padding: 2rem 1rem;
      >div{
        padding: 2rem;
        @include breakpoint(small){
          -webkit-box-shadow: -2px 5px 53px -20px rgba(113,167,255,1);
          -moz-box-shadow: -2px 5px 53px -20px rgba(113,167,255,1);
          box-shadow: -2px 5px 53px -20px rgba(113,167,255,1);
        }
      &.active{
        -webkit-box-shadow: -2px 5px 53px -20px rgba(113,167,255,1);
        -moz-box-shadow: -2px 5px 53px -20px rgba(113,167,255,1);
        box-shadow: -2px 5px 53px -20px rgba(113,167,255,1);
      }
    }
    }
  }

/*====================
    key Queries   
======================*/
  .key-queries{
    padding: 2rem 0;
    padding-bottom: 5rem;
    .carousel-inner .carousel-item{
      max-height: 80vh;
      max-width: 100vw;
    }
    .carousel-inner .carousel-item img{
      display:block;
      width:100%; 
      height:100%;
      object-fit: cover;
    }

    .carousel-caption{
      padding-bottom: 100px;
      @include breakpoint(small){
        padding-bottom: 130px;
      }
      @include breakpoint(xsmall){
        padding-bottom: 10px;
      }
    }

    .carousel-caption h2, .content-animation > div h5, .content-animation > div ol li{
      text-align: center;
      font-size: 25px;
      padding: 0 15px;
      color: #fff;
      width: max-content;
      background: rgba(0,0,0,0.5);
      animation-duration: 1s;
      animation-delay: 1s;
      @include breakpoint(small){
        font-size: 12px;
      }
    }

    .carousel-control-prev, .carousel-control-next{
      background-image: none;
    }

    .content-animation > div h5{
      font-size: 20px;
      margin: 10px 0;
      animation-duration: 1s;
      animation-delay: 2s;
      @include breakpoint(small){
        font-size: 10px;
      }
    }

    ol li{
      padding: 0;
      margin: 0;
    }

    .content-animation{
      padding: 0 !important;
    }

    .content-animation > div ol li{
      list-style: none;
      margin: 10px 0;
      font-size: 18px;
      @include breakpoint(small){
        font-size: 10px;
      }
    }

    .content{
      @include breakpoint(small){
        div{
          box-shadow: none;
        }
      }
      ol li:nth-child(1){
        animation-duration: 1s;
        animation-delay: 3s;
      }
      ol li:nth-child(2){
        animation-duration: 1s;
        animation-delay: 4s;
      }
      ol li:nth-child(3){
        animation-duration: 1s;
        animation-delay: 5s;
      }
    }

    @include breakpoint(small){
      .content-animation > div:nth-child(2){
        float: right;
      }
    }
  }

  //===============================================
  
  .clients-review{
    .info{
      width: 250px;
      min-height: 300px;
      &.ct{
        -webkit-box-shadow: 10px 10px 11px 2px rgba(113,167,255,0.75);
        -moz-box-shadow: 10px 10px 11px 2px rgba(113,167,255,0.75);
        box-shadow: 10px 10px 11px 2px rgba(113,167,255,0.75);
      }
    }
    .review-content{
      p{
        font-size: 1em;
      }
    }
  }

  .homepage{
    .header-title{
      margin-top: 40px;
    }
    @extend %try-for-free;
  }

.platform{
  .platform-begin .card{
    border-radius: 15px;
  }
  p{
    margin-bottom: 0;
  }
}

/*=====================
    Image Transition
=======================*/

.transition {
  text-align: center;
  position: relative;
  overflow: hidden;

  /* Card */
  .card {
    border: 3px #f3f3f3 solid;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    transition: all 0.3s linear;
    cursor: pointer;
    background-color: #f3f3f3;
    padding: 10px;
    max-width: 220px;
    text-align: left;
    margin: 0 auto;
    &:hover {
      background-color: #f3f3f3;
      border-color: #f3f3f3;
    }
    img {
      max-width: calc(100%);
      margin: 0 auto;
      display: block;
      height: 150px;
    }
    .index {
      background-color: rgba(0, 0, 0, 0.2);
      color: #fff;
      line-height: 20px;
      font-size: 14px;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      padding: 0 10px;
    }
    .price {
      margin: 0;
      padding: 10px 0;
      font-weight: bold;
    }
    .details {
      position: relative;
      margin-top: 5px;
      color: #2c2c2c;
      font-weight: bold;
    }
    .location{
      margin-bottom: 0;
    }
    .features {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        padding-left: 24px;
        margin-right: 10px;
        display: inline-block;
        span {
          display: none;
        }
        &.icon-bed {
          // background: url(./assets/bed-left-side.svg) left center;
          background-size: auto 100%;
          background-repeat: no-repeat;
        }
        &.icon-bath {
          // background: url(./assets/bathtub.svg);
          background-size: auto 100%;
          background-repeat: no-repeat;
        }
        &.icon-car {
          // background: url(./assets/car-compact.svg);
          background-size: auto 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }

  /*--- Card Slider ---*/
  .cards-slider-wrapper {
    display: flex;
    position: absolute;
    transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    .card {
      flex: 1;
      min-width: 200px;
      opacity: 0.7;
      transform: scale(0.8);
      box-shadow: none;
      background-color: white;
      border-color: white;
      transition: opacity 300ms linear, border-color 300ms linear, background-color 300ms linear,transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      .details {
        opacity: 0;
        transition: opacity 150ms linear;
      }
    }
  }
  
  $i: 0;
  @for $i from 0 through 29 {
    .cards-slider.active-slide-#{$i} #card-#{$i} {
      opacity: 1;
      transform: scale(1);
      background-color: #f3f3f3;
      border-color: #f3f3f3;
      box-shadow: 5px 5px 15px #f3f3f3;
      .details {
        opacity: 1;
      }
    }
  }

  .col {
    height: 264px;
    position: relative;
    &:before,
    &:after {
      content: '';
      display: block;
      width: 50%;
      height: 77%;
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    &:after {
      left: auto;
      right: 0;
      background: linear-gradient(to left, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .cards-slider {
    position: relative;
    max-width: 200px;
    margin: 0 auto; 
  }

  .cards-slider:after {
    content: '';
    display: block;
    width: 100%;
    height: 202px;
    outline: 5px solid #61DAFB;
    position: absolute;
    top: 0;
    left: 0; 
  }
}

//========================================================

/*=====================
        TEAM
=======================*/

.team{
  .single-team{
    background-color: rgba(6, 108, 255, 0.5);
    margin-bottom: 10px;
  }
  .single-team:hover .social{
    cursor: pointer;
    opacity: 1;
    transform: rotatey(0deg) scale(1,1);
  }
  .img-area{
    overflow: hidden;
    position: relative;
  }
  .img-area img{
    width: 100%;
    height: 250px;
    object-fit:cover;
  }
  .img-area:hover img{
    transform: scale(1.2);
  }
  .img-area img{
    transition: all 0.5s ease 0s;
    @include breakpoint(small){
      display: inline-block;
    }
  }
  .img-area .social{
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    text-align: center;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: .5s;
    transform: rotateY(180deg) scale(0.5, 0.5);
  }
  .img-area .social ul{
    text-align: center;
    position: relative;
    top: 50%;
  }
  .img-area .social ul li a{
    border: 1px solid #fff;
    color: #fff;
    display: block;
    font-size: 20px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
  }
  .img-area .social ul li a i{
    color: #fff;
  }
  .img-area .social ul li a:hover{
    background-color: #fff;
    color: #000;
    border: 1px solid transparent;
  }
  .img-area .social ul li:nth-child(1) a:hover i{
    color: #00f;
  }
  .img-area .social ul li:nth-child(2) a:hover i{
    color: #00aced;
  }
  .img-area .social ul li:nth-child(3) a:hover i{
    color: #bc2a8d;
  }
  .img-area .social ul li:nth-child(4) a:hover i{
    color: #007bb6;
  }
  .img-text{
    padding: 5px;
    color: #fff;
    text-align: center;
  }
  .img-text h4{
    color: #fff;
    margin: 0 0 5px;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 1px;
  }  
  .img-text h5{
    color: #fff;
    font-size: 17px;
    font-weight: bold;
  }  
}


/*====================
    Things Begin Card    
======================*/
.icon{
  position: relative;
  text-align: center;
}
.icon > .image{
  position: relative;
  z-index: 2;
  margin: auto;
  width: 78px;
  height: 78px;
  border: 8px solid white;
  line-height: 78px;
  border-radius: 50%;
  background: #066CFF;
  vertical-align: middle;
}

.platform .platform-begin:nth-child(1) .icon .image{
  background: $purple-gradient;
}
.platform .platform-begin:nth-child(2) .icon .image{
  background: $young-passion-gradient;
}
.platform .platform-begin:nth-child(3) .icon .image{
  background: $near-moon-gradient;
}

.icon:hover > .image {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: rotate(360deg) scale(1.2);
  -moz-transform: rotate(360deg) scale(1.2);
  -o-transform: rotate(360deg) scale(1.2);
  -ms-transform: rotate(360deg) scale(1.2);
  transform: rotate(360deg) scale(1.2);
}

.icon > .image > i {
  font-size: 36px !important;
  color: #fff !important;
}

.icon:hover > .image > i {
  color: white !important;
}

.platform > .description > p {
  font-size: 16px; 
  color: #222; 
  font-weight: 500;
}

/*====================
        LOCATION    
======================*/

.location_wrapper {
  position:relative;
}

.location_tag {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.location_tag div{
  background: #2c2c2c;
  color: #ffffff;
  text-transform: uppercase;
  width: 220px;
  margin: 0 auto;
  font-size: 29px;
  padding: 15px 20px;
  text-align: center;
}

/*====================
        FOOTER    
======================*/
.footer{
  background-color: #D8E8FF;
  padding-top: 50px;
  .foo_title_heading, .foo_title{
    font-size: 28px;
    font-weight: bold;
    background-image: -webkit-$young-passion-gradient;
    background-image: $young-passion-gradient;
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
  .foo_title{
    background-image: -webkit-$blue-gradient;
    background-image: $blue-gradient;
  }
  .foo_heading{
    color: #2c2c2c;
    font-size: 22px;
    font-weight: bold;
  }
  .foo_content{
    color: #2c2c2c;
    font-size: 15px;
    margin-top: 20px;
    ul li{
      list-style: none;
      margin: 10px 0;
    }
    ul li a {
      color: #262626;
    }
    ul li a:hover{
      color: #1890ff;
    }
  }
  .foo_details, .foo_contact_details, .foo_contact_social{
    margin-top: 20px;
  }
  .foo_contact_details > .nfo{
    display: inline;
    margin-left: 10px;
  }
  .foo_contact_social{
    position: relative;
    .foo_social_icons {
      position: relative;;
      top:25px;
      left:47%;
      transform:translate(-50%,-50%);
      margin:0;
      padding:0;
      display:flex;
    }
    .foo_social_icons li {
        list-style:none;
    }
    .foo_social_icons li a {
        position: relative;
        width: 40px;
        height: 40px;
        display: block;
        text-align: center;
        margin: 0 10px;
        border-radius: 50%;
        padding: 3px;
        box-sizing: border-box;
        text-decoration: none;
        box-shadow: 0 10px 15px rgba(0,0,0,0.3);
        background: linear-gradient(0deg, #ddd, #fff);
        transition: .5s;
    }
    .foo_social_icons li a:hover {
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    }
    .foo_social_icons li a .fab, .foo_social_icons li a .fas{
        width:100%;
        height: 100%;
        display: block;
        background: linear-gradient(0deg, #fff, #ddd);
        color: #262626;
        border-radius: 50%;
        line-height: calc(60px - 24px);
        font-size: 15px;
    }
    .foo_social_icons li:nth-child(1) a:hover .fas {
      color: #C0392B;
    }
    .foo_social_icons li:nth-child(2) a:hover .fab {
        color: #00f;
    }
    .foo_social_icons li:nth-child(3) a:hover .fab {
        color: #00aced;
    }
    .foo_social_icons li:nth-child(4) a:hover .fab {
        color: #dd4b39;
    }
    .foo_social_icons li:nth-child(5) a:hover .fab {
        color: #007bb6;
    }
    .foo_social_icons li:nth-child(6) a:hover .fab {
        color: #bc2a8d;
    }
  }
  .foo_timings > .foo_timingHrs{
    display: inline;
    margin-left: 10px;
  }
  .subscribe{
    margin-top: 10px;
  }
  .subscribe input{
    padding: 10px;
    border: none;
    @include breakpoint(medium_and_smaller){
      display: block;
      width: 100%;
      text-align: center;
    }
    @include breakpoint(small){
      margin-bottom: 20px;
    }
  }
  .subscribe input[type="submit"]{
    background: #00f;
    color: #fff;
    font-weight: bold;
    @include breakpoint(medium_and_smaller){
      margin-top: 5px;
    }
  }
  .subscribe input[type="submit"]:hover{
    background: #dd4b39;
  }
  .foo_copyrights{
    background: #066CFF;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .foo_powered_by{
    font-size: 9px;
    font-weight: bold;
  }
}