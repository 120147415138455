.aap-btn{
  color: #fff;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 30px;
  font-weight: bold;
  border: 1px solid #fff;
  font-size: 1em;
  &:hover{
    color: #fff;
  }
  box-shadow: none;
  text-transform: none;
  padding-top: .39rem;
  padding-bottom: .39rem;
  margin: 0;
  @include breakpoint(xsmall){
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    border-radius: 30px;
  }
}
.aap-primary-btn{
  border: none;
  background-color: $aap-primary-btn-bg-color;
  color: $aap-primary-btn-color;
}

.aap-warning-btn{
  border: none;
  background-color: $aap-warning-btn-bg-color;
  color: $aap-warning-btn-color;
}