/*--- Loading ---*/
.cover-spin{
    position: fixed;
    background-color: black;
    opacity: 0.4;
    font-size: 30px;
    left : 0;
    bottom : 0;
    right : 0;
    top : 0;
    z-index: 1000;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cover-spin:after{
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(7,end) 1000ms infinite;      
    animation: ellipsis steps(7,end) 1000ms infinite;
    content: "Loading\2026\2026"; /* ascii code for the ellipsis character */
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 6em;    
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 6em;    
    }
}