.Cashflow360 {
    width: 100%;
    height: 400px;
    padding: 0;
}

.cashflow {
    .icon {
        border-radius: 30px;
    }
    
    .icon i {
        padding: 15px;
        color: #fff;
        font-size: 25px;
    }

    .card-title, .card-title-number {
        padding-top: 15px;
    }
}

.card-titleYear {
    font-size: 11px;
}

.cashflow-360 .cashflow:nth-child(1) .icon{
    background: $aqua-gradient;
}
.cashflow-360 .cashflow:nth-child(2) .icon{
    background: $young-passion-gradient;
}
.cashflow-360 .cashflow:nth-child(3) .icon{
    background: $blue-gradient;
}
.cashflow-360 .cashflow:nth-child(4) .icon{
    background: $purple-gradient;
}

.monthlyInflowOutflow {
    .axis .domain {
        display: none;
    }
    .grid path {
        stroke: none;
    }
    .cir1{
        stroke:gray;
        stroke-width: 2px; 
        fill:#0077FF;
        border-radius:50%;
        box-shadow: 10px -10px rgb(190, 70, 70);
        
    }
    .grid line {
        stroke: #E0E0E0;
        shape-rendering: crispEdges;
    }
}