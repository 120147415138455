$wd-device: 1200px;
$lg-device: 992px;
$md-device: 768px;
$sm-device: 576px;

@mixin breakpoint($point) {
  @if $point == wide{
    @media only screen and (min-width: $wd-device + 1) { @content; }
  }
  @else if $point == xsmall {
    @media only screen and (max-width: $sm-device){
      @content;
    }
  }
  @else if $point == medium {
    @media only screen and (min-width: $md-device){
      @content;
    }
  }
  @else if $point == medium_and_smaller {
    @media only screen and (min-width: $md-device) and (max-width: $lg-device){
      @content;
    }
  }
  @else if $point == small {
    @media only screen and(max-width: $md-device) {
      @content;
    }
  }
  @else if $point == smaller_and_medium {
    @media only screen and (min-width: $sm-device) and (max-width: $md-device){
      @content;
    }
  }
}
