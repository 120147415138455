@import './sass/index';
body {
  zoom: 1;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: 'Muli', sans-serif;
  margin: 0px;
  padding: 0px;
}